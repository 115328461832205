import '../src/css/public'

const images = require.context('../src/img', true)
const fonts = require.context('../src/fonts', true)

import jquery from "jquery";

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Node modules
import 'bootstrap';
import 'waypoints/lib/jquery.waypoints';
import { Fancybox } from "@fancyapps/ui";
import 'jquery.cookie'
import Swal from 'sweetalert2';
import Swiper from '../src/js/swiper.min' 
import moment from 'moment';
import 'jquery.maskedinput/src/jquery.maskedinput';
import 'daterangepicker/daterangepicker';
import '@chenfengyuan/datepicker';
import 'slick-carousel';
import HystModal from 'hystmodal/src/hystmodal';
import intlTelInput from '../src/js/intlTelInput';

import { I18n } from "i18n-js";
import ru from "translations/ru.json";
import en from "translations/en.json";
const i18n = new I18n();
i18n.store(en);
i18n.store(ru);
i18n.missingBehavior = "guess";

// Меню для мобильных устройств
import '../src/js/jquery.dlmenu';
import '../src/js/jquery.dlmenu.autofill';
import '../src/js/bootstrap-hover-dropdown';
import '../src/js/wp.app';
import '../src/js/navbar.multi';
import '../src/js/jquery.uitotop';
import '../src/js/device';
import '../src/js/zabuto_calendar';

global.moment = moment;
global.Swiper = Swiper;
global.i18n = i18n;
global.intlTelInput = intlTelInput;

$(document).ready(function() {
  $.removeCookie('tenge_som_qr_modal', {path: "/"});
  let lang = $.cookie($('#locale_cookie_key').val());
  i18n.locale = lang;
  moment.locale(i18n.locale);

  initializeDonationForm();
  prepareDonationForm();
  let yandex_metrika = $('#yandex_metrika').val();
  // Карусель на странице новости, статьи и мероприятия
  $(".carousel .change-photo").on("click",function(){
    let photo=$(this).attr("data-big");
    let href=$(this).attr("href");
    if(typeof photo !=="undefined" && typeof href !=="undefined")
    {
      $(this).addClass("active").parents(".carousel").find(".change-photo.active").not($(this)).removeClass("active");
      $("#big-photo").attr("href", href).children().attr("src", photo);
    }
    return false;
  });

  // модалка фандрайзера
  global.modal_fand = new HystModal({
    linkAttributeName: "data-hystmodal"
  });

  // Подписка на новости
  $("#new_subscription").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_subscription")[0].reset();
            ToggleEnable('new_subscription');
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      executeRecaptchaForNewSubscription();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // Обратная связь
  $(".feedback-form").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data.saved) {
          swalSuccess();
          $(".feedback-form")[0].reset();
          $('#form_call').removeClass('hystmodal--active');
          $('.hystmodal__shadow').removeClass('hystmodal__shadow--show');
        }
        else {
          swalErrors(data.errors);
        }
      },
      error: function (data) {
        swalInternalError();
      }
    });
    executeRecaptchaForFeedback();
  });

  // Заявка на волонтерство
  $("#new_volunteer").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_volunteer")[0].reset();
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      executeRecaptchaForSaveVolunteer();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // Заявка на сотрудничество
  $("#new_cooperation_request").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);

    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_cooperation_request")[0].reset();
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      executeRecaptchaForSaveCooperationRequest();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // Заявка на помощь
  $("#new_request").submit(function (e) {
    e.preventDefault();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    if ($('#personal_data').prop('checked') === true) {
      $.ajax({
        url: url,
        type: "POST",
        dataType: "json",
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          if (data.saved) {
            swalSuccess();
            $("#new_request")[0].reset();
          }
          else {
            swalErrors(data.errors);
          }
        },
        error: function (data) {
          swalInternalError();
        }
      });
      executeRecaptchaForSaveHelpRequest();
    }
    else {
      swalErrors([i18n.t('accept_personal_data')]);
    }
  });

  // $(".phone_mask").mask("+9(999)999-9999");

  // Навигация на мобильных устройствах
  $('#sf-nav').sfNavMulti({
    searchContainer: true,
    horizontalSubmenu: true,
    transferHeadingItem: true,
    fixedFlag: true,
    fixedId: "#sf-fixed-id",
    fixedClass: 'nav-fixed',
    fullScreenFlag: true,
    fullScreenTheme: 'theme-light full-screen-color',
    transformFlag: true,
    transformPaddingOne: 't-0 p-3',
    transformPaddingLower: '',
    transformEffectHover: 'blackout',
    transformTheme: 'theme-dark child-color-submenu fixed-theme-light-submenu fixed-submenu-color',
    mobileFlag: true
  });

  // Галерея
  Fancybox.bind("[data-fancybox]", {});
  Fancybox.bind(".theater", {});

  // Подтверждение использования кукисов
  checkCookies();

  // Слайдер изображений
  $('.slide-slick').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  });

  // Скрывает показывает описание галереи
  $('.link-gallery').click(function() {
    $(this).parents('.col-md-12').find('.photo-desc').toggleClass('active')
  })

  // Выравнивает по высоте блоки с разной высотой, берет большую(главная новости)
  function setEqualHeight(columns) {
    var tallestcolumn = 0;
    columns.each(
      function() {
        const currentHeight = $(this).height();
        if (currentHeight > tallestcolumn) {
          tallestcolumn = currentHeight;
        }
      }
    );
    columns.height(tallestcolumn);
  }
  setEqualHeight($(".item-slide .diagnosis"));
  setEqualHeight($(".item-slide .measures"));
  setEqualHeight($(".already-help__desc .diagnosis"));
  setEqualHeight($(".already-help__desc .anounces"));
  setEqualHeight($(".small li:first-child"));
  setEqualHeight($(".small li:nth-child(2)"));
  setEqualHeight($(".small li:nth-child(3)"));
  setEqualHeight($(".inverse h4.mt-15"));
  setEqualHeight($(".article .main-news-name"));
  setEqualHeight($(".article .main-programm-name"));
  setEqualHeight($(".article .subtitle"));
  setEqualHeight($(".article .news_subtitle"));

  // Табы в детях
  $(function() {
    $('ul.tabs-list li:first-child').addClass('active');
    $('.tab-content-item:first-child').addClass('active');
    $('ul.tabs-list').on('click', 'li:not(.active)', function() {
      $(this).addClass('active').siblings().removeClass('active').closest('div.tabs-wrap').find('div.tab-content-item').removeClass('active').eq($(this).index()).addClass('active');
    });
  });
});

// animation function
global.animationIn = function animationIn(animationId, msDelay, nameAnimation) {
  $(animationId).css('animation-delay', msDelay / 1000 + 's');
  $(animationId).addClass(nameAnimation + ' animated').one('animationend webkitAnimationEnd mozAnimationEnd MSAnimationEnd oAnimationEnd',
    function () {
      $(this).removeClass(nameAnimation + ' animated');
  });
}

// Отключает возможность повтороной подписки на новости
global.ToggleEnable = function ToggleEnable(form_id) {
  var form = document.getElementById(form_id);
  var ells = form.querySelectorAll("input,textarea");
  for (var el of ells) {
    if (el.getAttribute("disabled") == "disable") {
      el.removeAttribute("disabled");
    } else {
      el.setAttribute("disabled", "disable");
    }
  }
};

global.swalSuccess = () => {
  Swal.fire({
    title: i18n.t('message_sent'),
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('we_will_contact_soon')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalInternalError = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('unexpected_error')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalPaymentMethodUnavailable = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('payment_method_unavailable')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalErrorPhone = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('enter_correct_phone')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalErrorAmount = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('enter_donation_amount')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
};

global.swalErrorMail = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('enter_correct_email')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrorOffer = () => {
  Swal.fire({
    title: '',
    type: '',
    showConfirmButton: true,
    html: `<p style="color: #000;width: 95%;">${i18n.t('agree_personal_data')}</p><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button>`
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

global.swalErrors = (errors) => {
  let html = `<p style="color: #004c75;">${i18n.t('fix_errors')}:</p><ol>`
  $(errors).each(function() {
    html += `<li>${this}</li>`
  });
  html += '</ol><button class="action dialog__close"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="16.9707" width="2" height="24" transform="rotate(45 16.9707 0)" fill="#ACACBC"/><rect y="1.41431" width="2" height="24" transform="rotate(-45 0 1.41431)" fill="#ACACBC"/></svg></button></button>'
  Swal.fire({
    title: i18n.t('found_errors'),
    type: 'error',
    showConfirmButton: true,
    html: html
  });

  $('.swal2-container .action').on('click', function() {
    Swal.close();
  });
}

// Проверка использованиия Cookie
function checkCookies() {
  let use_cookies = $.cookie('use_cookies');
  let cookieNotification = document.getElementById('cookie_notification');
  let cookieBtn = cookieNotification.querySelector('.cookie_accept');
  if (!use_cookies) {
    cookieNotification.classList.add('show');
    cookieBtn.addEventListener('click', function() {
      $.cookie('use_cookies', true);
      cookieNotification.classList.remove('show');
    });
  }
}

// Копирование текста(фандрайзер)
global.copytext = function copytext(el) {
  let $tmp = $("<input>");
  $("body").append($tmp);
  $tmp.val($(el).text()).select();
  document.execCommand("copy");
  $tmp.remove();
  $('#modal_fand').removeClass('hystmodal--active');
  $('.hystmodal__shadow').removeClass('hystmodal__shadow--show');
}

// Проверка корректности ввода почты
global.emailCheck = function emailCheck (email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// Расчет итоговой суммы пожертвования
global.calc_summary = function calc_summary(form) {
  $(form).find('.itog_p b').html('0');
  let count = 1;
  $(form).find('.checkbox-form-move').each(function() {
    if ($(this).is(':checked')) {
      count = count + 1;
    }
  })
  let val = $(form).find('.payment_amount').val();
  $(form).find('.itog_p b').html(parseInt(val) * count);
}

function changeCurrency(currency) {
  $.cookie("currency_symbol",currency, {expires: 1});

  onCurrencyChanged(currency);
}

function onCurrencyChanged(newCurrency) {
  document.querySelector('.n-p__some-much span').innerText = newCurrency
  document.querySelector('.itog_p font').innerText = newCurrency

  document.getElementById('final-amount').classList.remove('d-none');

  showCardForCurrency(newCurrency);
  updateBannersCurrency(newCurrency);
  updateQrCode(newCurrency);
}

global.onQrModalChanged = function onQrModalChanged(newCurrency) {
  const modal = $('.n-p__tenge_som_qr_modal-form.p-50');
  switch (newCurrency) {
    case 'C':
      modal.removeClass('small');
      break;
    case '$':
      if (!modal.hasClass('small')) {
        modal.addClass('small');
      }
      break;
    case '€':
      if (!modal.hasClass('small')) {
        modal.addClass('small');
      }
      break;
    case '₸':
      if (!modal.hasClass('kz')) {
        modal.addClass('kz');
      }
      modal.removeClass('small');
      break;
  }
}

function showCardForCurrency(currency) {
  const cards = new Map([
    ['$', 'card-payment__card-us'],
    ['€', 'card-payment__card-eu'],
    ['C', 'card-payment__card-kg'],
    ['₸', 'card-payment__card-kz']
  ]);

  const cardForCurrency = cards.get(currency);

  if (!cardForCurrency) { return; }

  for (const [_key, value] of cards) {
    if (value === cardForCurrency) {
      $(`.${cardForCurrency}`).removeClass('d-none');
    } else {
      $(`.${value}`).addClass('d-none');
    }
  }
}

global.updateQrCode = function updateQrCode(currency) {

  const qrCodes = new Map([
    ['$', 'dollar_qr_img'],
    ['€', 'euro_qr_img'],
    ['C', 'mbank_qr_img'],
    ['₸', 'kaspi_qr_img']
  ]);

  const qrCodeForCurrency = qrCodes.get(currency);


  for (const [_key, value] of qrCodes) {
    if (value === qrCodeForCurrency) {
      $(`.${qrCodeForCurrency}`).removeClass('d-none');
    } else {
      $(`.${value}`).addClass('d-none');
    }
  }

}

function updateIntlInputPhone() {
  let newCurrency = $.cookie('currency_symbol');
  const input = document.querySelector("#donation_phone");
  if (!input) { return; }
  const countryCodes = new Map([
      ['€','at'],
      ['$','us'],
      ['₸','kz'],
      ['C','kg'],
  ]);
  const countryCode = countryCodes.get(newCurrency);
  if (!countryCode) { return; }
  const intlInputPhone = intlTelInputGlobals?.getInstance(input);
  intlInputPhone?.setCountry(countryCode);
}

function updateBannersCurrency(currency) {
  const banners = document.getElementsByClassName('n-p__some-numeral');
  for(let i = 0; i < banners.length; i++) {
    banners[i].getElementsByTagName('span')[1].innerText = currency;
  }
}

function setCurrency() {
  let active_tab = $('.n-p__tabs ul li.active');

  if (active_tab.attr('id') === 'requisites') {
    document.getElementById('final-amount').classList.add('d-none')
  } else if (active_tab.attr('id') === 'card') {

    const currency_symbol = $.cookie('currency_symbol');

    if (currency_symbol === '€') {
      $("#euro_card").click();
      changeCurrency('€');
    } else if (currency_symbol === '₸') {
      $("#tenge_card").click();
      changeCurrency('₸');
    } else if (currency_symbol === 'C') {
      $("#som_card").click();
      changeCurrency('C');
    } else {
      $("#dollar_card").click();
      changeCurrency('$');
    }
  } else if (active_tab.attr('id') === 'from_kg') {
    $("#from_kg_bankcard").click();
    changeCurrency('C');
  }
}

global.callDonateForm = function callDonateForm(url, formData) {
  let tag = $.cookie('FAND');
  let fundraiser = $('#fundraiser').val();
  prepareQrModals();

  formData.append('donation[tag]', tag);
  formData.append('donation[fundraiser]', fundraiser);
  // formData.append('g-recaptcha-response-data[donate]', donate_recaptcha);
  $.ajax({
    url: url,
    type: "POST",
    dataType: "json",
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
    success: function (data) {
      console.log('success');
      console.log(data);
      if (data.saved) {
        try {
          ym(Number(yandex_metrika.value), 'reachGoal', 'zayavka');
          VK.Goal('lead');
        } catch { }

        if (data.payment_gateway === 'cloudpayments') {
          cloudPaymentPay(data.public_key, data.email, data.payment_id, data.description, data.amount, data.currency)
        } else if (data.payment_gateway === 'paybox') {
          window.location.href = data.payment_url;
        } else {
          //swalInternalError();
        }
      } else {
        //swalErrors(data.errors);
      }
    },
    error: function (data) {
      console.log('error');
      console.log(data);
      //swalInternalError();
    }
  });
}

global.initializeDonationForm = function initializeDonationForm() {
  setCurrency();
  // Обработка формы пожертвования
  $(".donate-form").on('submit', async function (e) {
    e.preventDefault();

      $('.n-p__final-form').removeClass('active');
      $('.n-p__layer-sec').removeClass('active');
      $('.tenge_som_qr_modal').removeClass("hidden");
      $('.n-p__tenge_som_qr_modal-form').toggleClass('active');
      $('.n-p__layer-sec').toggleClass('active');

    // let donate_recaptcha = await executeRecaptchaForDonateAsync();
    let form = $(this);
    let url = form.attr('action');
    let formData = new FormData(this);
    let errors = false;
    let email = formData.get('donation[email]');

    if (formData.get('donation[recurrent]')) {
      //swalPaymentMethodUnavailable();
      errors = true
    }

    if (formData.get('donation[amount]') === '') {
      //swalErrorAmount();
      errors = true
    }

    if (formData.get('donation[payment_method]') === 'mobile') {
      if (formData.get('donation[phone]') === '') {
        //swalErrorPhone();
        errors = true
      }
    }
    else {
      if (email === '' || emailCheck(email) == null) {
        //swalErrorMail();
        // errors = true
      }
    }

    if (form.closest('.row').find('#public_offer').length > 0 && form.closest('.row').find('#public_offer').is(":checked") == false) {
      //swalErrorOffer();
      errors = true
    }

    if (!errors) {
      callDonateForm(url, formData);
    }
    return errors;
  });
  // Меняем валюту при выборе валюты во вкладке картой
  $('input[type=radio][name="donation[payment_method]"]').on('change', function() {
    const modal = $('.n-p__tenge_som_qr_modal-form.p-50');
    switch ($(this).val()) {
      case 'qr_code_method_kgs':
        changeCurrency('C');
        modal.removeClass('small');
        break;
      case 'qr_code_method_usd':
        changeCurrency('$');
        if (!modal.hasClass('small')) {
          modal.addClass('small');
        }
        break;
      case 'qr_code_method_eur':
        changeCurrency('€');
        if (!modal.hasClass('small')) {
          modal.addClass('small');
        }
        break;
      case 'qr_code_method_kzt':
        if (!modal.hasClass('kz')) {
          modal.addClass('kz');
        }
        changeCurrency('₸');
        modal.removeClass('small');
        break;
    }
  });

  // табы видов оплаты
  $('.n-p__tabs ul li').on('click', function () {
    $(this).addClass('active')
    .siblings()
    .removeClass('active')
    .closest('div.n-p__tabs')
    .find('div.n-p__tabs-content')
    .removeClass('active').eq($(this).index()).addClass('active');

    setCurrency();
  });

  // События появления форм
  $('.n-p__submit').on('click', function () {
    let errors = false
    //console.log($(this).closest('form'));
    if ($(this).closest('form').find('.payment_amount').val() <= 0 ) {
      errors = true
      swalErrorAmount();
    }

    if (!errors) {
      if ($.cookie('direct_donate_form')) {
        let url = $(this).closest('form').attr('action');
        let formData = new FormData($(this).closest('form')[0]);
        callDonateForm(url, formData);
      } else {
        $('.n-p__final-form').toggleClass('active');
        $('.n-p__layer-sec').toggleClass('active');

        const input = document.querySelector("#donation_phone");
        if (input) {

          let code = $.cookie('country_code');
          let intl_country = ['eu','us','kz','kg'].includes(code.toLowerCase()) ? code.toLowerCase() : 'us'

          intlTelInput(input, {
            nationalMode: false,
            preferredCountries: ['kg', 'kz'],
            initialCountry: 'kg',
            utilsScript: '/js/utils.js'
          })

          //updateIntlInputPhone();
        }
      }
    }
  });

  $('.n-p__final-close').on('click', function () {
    $('.n-p__final-form').removeClass('active');
    $('.n-p__layer-sec').removeClass('active');
    $('.n-p__tenge_som_qr_modal-form').removeClass('kz');
  });

  $('.n-p__tenge_som_qr_modal-close').on('click', function () {
    $('.n-p__tenge_som_qr_modal-form').removeClass('active');
    $('.n-p__layer-sec').removeClass('active');
    $('.n-p__tenge_som_qr_modal-form').removeClass('kz');
  });

  $('.n-p__list-more').on('click', function () {
    $('.n-p__some').toggleClass('hiddens');
  });

  // Выбор ребенка на странице пожертвования
  $('.checkbox-form-move').on('change', function () {
    let form = $(this).closest('form');
    calc_summary(form);
  });

  // Изменение суммы пожертвования
  $('.payment_amount').on('change', function () {
    let form = $(this).closest('form');
    form.find('.veof span').html($(this).val());
    calc_summary(form);
  });

  $('.payment_amount').on('keyup', function () {
    let form = $(this).closest('form');
    form.find('.veof span').html($(this).val());
    calc_summary(form);
  });

  //для нормального позиционирования абсолютом всей платилки
  $('.n-p__layer').parent('div.col-md-8').css('position', 'static');
  $('.n-p__layer').parent('div.body-wrap').css('overflow', 'none !important');

  // табы видов оплаты
  $('.n-p__tabs ul').on('click', 'li:not(.active)', function () {
    $(this)
    .addClass('active').siblings().removeClass('active')
    .closest('div.n-p__tabs').find('div.n-p__tabs-content').removeClass('active').eq($(this).index()).addClass('active');
  });
  $('.n-p__tabs ul').on('click', 'li:not(.active)', function () {
    $(this)
    .addClass('active').siblings().removeClass('active')
    .closest('div.n-p__tabs').find('div.n-p__tabs-content').removeClass('active').eq($(this).index()).addClass('active');
  });

  $(".date_mask").mask("99.99.9999");
}


global.prepareDonationForm = function prepareDonationForm() {

  const country_code = $.cookie('country_code').toLowerCase();
  if (country_code === 'kz') {
    updateQrCode('₸')
  } else if (country_code === 'kg') {
    updateQrCode('C')
  }

  let tag = $.cookie('FAND');
  let fundraiser = $.cookie('fundraiser');
  let amount = $.cookie('amount');

  $('.donate-form #donation_tag').val(tag);
  $('.donate-form #donation_fundraiser').val(fundraiser);
  $('.donate-form .total-donation-amount').html(amount);
  $('.donate-form .donation-amount').html(amount);
  $('.donate-form .payment_amount').val(amount);

  if ($.cookie('direct_donate_form')) {
    let payment_methods = $.cookie('payment_method') ? $.cookie('payment_method').split(',') : [];

    $('.donate-form ul.donation-tabs').addClass('hidden');
    $('.donate-form h3.donation-title').addClass('hidden');
    $('.donate-form .donation-text').addClass('hidden');
    $('.donate-form .n-p__final h2.itog_p').addClass('hidden');
    $('.donate-form .n-p__tenge_som_qr_modal h2.itog_p').addClass('hidden');
    $('.donate-form .n-p__final .public_offer_container').addClass('hidden');
    $('.donate-form .n-p__tenge_som_qr_modal .public_offer_container').addClass('hidden');
    $('.donate-form .n-p__final .subs_container').addClass('hidden');
    $('.donate-form .n-p__tenge_som_qr_modal .subs_container').addClass('hidden');
    $('.donate-form .n-p__list').addClass('hidden');
    $('.donate-form .n-p__final a.n-p__submit').css('margin-top', '60px');
    $('.donate-form .n-p__tenge_som_qr_modal a.n-p__submit').css('margin-top', '60px');
    if ($.cookie('payment_method').split(',').length < 2) {
      $('.n-p__tabs-types').addClass('hidden')
      $('.n-p__some.n-p__some-right h2').text('Введите сумму пожертвования')
    }
    $(`.donate-form #donation_payment_method_${payment_methods[0]}`).click();
    let all_payment_methods = ['qr_code_method', 'qr_code_method_usd', 'qr_code_method_eur', 'qr_code_method_kzt', 'paybox_wallets', 'paybox_bank', 'paybox_mobile'];
    let hidden_methods = all_payment_methods.filter(n => !payment_methods.includes(n))
    $(hidden_methods).each(function() {
      $(`.${this}-payment`).addClass('hidden');
    });
  }
}

global.prepareQrModals = function prepareQrModals() {
  let currency = $('.row.donation-page__currencies-container').find('input:checked')[0];
  switch (currency?.id) {
    case 'som_card':
      $('.n-p__tenge_som_qr_modal-form.p-50').removeClass('small');
      break;
    case 'tenge_card':
      $('.n-p__tenge_som_qr_modal-form.p-50').removeClass('small');
      if (!$('.n-p__tenge_som_qr_modal-form.p-50').hasClass('kz')) {
        $('.n-p__tenge_som_qr_modal-form.p-50').addClass('kz');
      }
      break;
    case 'dollar_card':
      if (!$('.n-p__tenge_som_qr_modal-form.p-50').hasClass('small')) {
        $('.n-p__tenge_som_qr_modal-form.p-50').addClass('small');
      }
      break;
    case 'euro_card':
      if (!$('.n-p__tenge_som_qr_modal-form.p-50').hasClass('small')) {
        $('.n-p__tenge_som_qr_modal-form.p-50').addClass('small');
      }
      break;
    default:
  }
}

global.openDonationModal = function openDonationModal(selected) {
  let url = $(selected).data('url');
  $("#tenge_som_qr_modal").addClass('hidden')
  $("#tenge_som_qr_modal_2").addClass('hidden')

  $.ajax({
    url: url,
    type: "GET",
    dataType: "html",
    success: function (data) {
      $('#donationModalContent .n-p').html(data);
      initializeDonationForm();
      prepareDonationForm();
      //qrPayDonation();
      $('#donationModal').modal('show');

      $('#donationModal').on('shown.bs.modal',function(){

        const country = $.cookie('country_code').toLowerCase();

        if (country === 'kg') {
          showCardForCurrency('C');
        } else if (country === 'kz' ) {
          showCardForCurrency('₸')
        } else {
          showCardForCurrency('$');
        }

        $("#card").click();

        // Prevents number inputs from changing value when scrolling and focusing
        $('form').on('focus', 'input[type=number]', function (e) {
          $(this).on('wheel.disableScroll', function (e) {
            e.preventDefault()
          })
        });
        $('form').on('blur', 'input[type=number]', function (e) {
          $(this).off('wheel.disableScroll')
        });

      });

    }
  });
}

global.directDonation = function directDonation(selected) {
  let formData = new FormData();

  let url = $(selected).data('url');
  let amount = $(selected).data('amount');
  let tag = $(selected).data('tag');
  let fundraiser = $(selected).data('fundraiser');
  let payment_method = $(selected).data('payment-method');
  let fundraisingId = selected ? ($(selected).data('fundraising') || []) : [];
  let authenticityToken = $(selected).data('token');

  formData.append('authenticity_token', authenticityToken);
  formData.append('direct_donate_form', true);
  formData.append('donation[fundraising_id]', fundraisingId);
  formData.append('donation[amount]', amount);
  formData.append('donation[tag]', tag);
  formData.append('donation[fundraiser]', fundraiser);
  formData.append('donation[payment_method]', payment_method);
  callDonateForm(url, formData);
}

function qrPayDonation() {
    const submitButton = document.querySelector('.  show-qr-submit-button');
    $('#personal_data').prop('checked',true)
    $('#donation_payment_method').prop('value','sbp_qr')
    if (!submitButton) { return;}
    submitButton.addEventListener('click', function () {
      $(".donate-form").submit();
    });
}

function cloudPaymentPay(public_key, email, payment_id, desc, amount, currency) {
  $('body').find("#donationModalContent").empty();
  let widget = new cp.CloudPayments();
  widget.pay('auth',
    {
      publicId: public_key,
      description: desc,
      amount: amount,
      currency: currency,
      accountId: email,
      invoiceId: payment_id,
      email: email,
      skin: "mini",
      data: {
        myProp: ''
      }
    },
    {
      onSuccess: function (options) {
        window.location.href = '/success-pay';
      },
      onFail: function (reason, options) {
        window.location.href = '/error_pay';
      },
      onComplete: function (paymentResult, options) {
        window.location.href = '/success-pay';
      }
    }
  )
}

global.drawContactsMap = function drawContactsMap (points) {
  const locations = [];
  points = points.split(";")
  for (const point of points) {
    let extracted_point = point.split(",")
    locations.push(['Sunofhope', extracted_point[0], extracted_point[1], 4])
  }

  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 7,
    center: new google.maps.LatLng(locations[0][1], locations[0][2]),
    mapTypeId: google.maps.MapTypeId.ROADMAP
  });

  var infowindow = new google.maps.InfoWindow();

  var marker, i;

  for (i = 0; i < locations.length; i++) {
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(locations[i][1], locations[i][2]),
      map: map
    });

    google.maps.event.addListener(marker, 'click', (function(marker, i) {
      return function() {
        infowindow.setContent(locations[i][0]);
        infowindow.open(map, marker);
      }
    })(marker, i));
  }


}